// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/routes/_auth.sign-in.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/routes/_auth.sign-in.tsx");
  import.meta.hot.lastModified = "1728581595818.388";
}
// REMIX HMR END

import { useFetcher, useLoaderData, useSearchParams } from '@remix-run/react';
import { json } from '@remix-run/server-runtime';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '~ui/Button';
import { LoadingIndicator } from '~ui/LoadingIndicator';
import { sessionStorage } from '~admin/sessions.server';
import { auth } from '~admin/components/auth/index.server';
import { getAlertServer } from '~common/components/alert/server/index.server';
import { getActiveAdministrator } from '~admin/providers/administrator/provider';
import { adminSettings } from '~admin/providers/global-settings/provider';
import { ErrorBox, PasswordInput, SuccessBox } from '~ui';
import clsx from 'clsx';
import { isJson } from '~common/utils/stringUtils';
import { FormInput } from '~ui/FormInput';
export async function action({
  params,
  request
}) {
  const alertServer = getAlertServer();
  let headers;
  const formData = await request.formData();
  let redirectTo = formData.get('redirectTo');
  let emailAddress = formData.get('email');
  let token = formData.get('token');
  let pw = formData.get('password');
  const action = formData.get('strategy');
  if (
  // @ts-ignore
  token?.length > 0 && pw?.length) {
    redirectTo = redirectTo?.length ? redirectTo : '/register';
    // const result = await verifyCustomerAccount({ request }, token!, pw)
    // if (result.__typename === 'CurrentUser') {
    //   console.log('headers: ' + JSON.stringify(result._headers))
    //   return redirect(redirectTo, { headers: result._headers })
    // } else {
    //   console.log('Not logged in:' + JSON.stringify(result))
    //   return json(result, {
    //     status: 401,
    //   })
    // }
  } else {
    redirectTo = redirectTo?.length ? redirectTo : '/';
  }
  let googleClientId;
  const {
    settings
  } = await adminSettings({
    request
  });
  try {
    switch (action) {
      case 'google':
        {
          googleClientId = settings.globalSettings.googleClientId;
          if (!googleClientId) {
            return alertServer.redirectWithError('/sign-in', {
              title: 'Error',
              message: 'Missing googleClientId'
            }, {
              headers
            });
          }
          const resp = await auth.authenticate('oauth2', request, {
            successRedirect: `/api/auth/post-sign-in?redirectTo=${redirectTo}`,
            failureRedirect: '/sign-in',
            context: {
              clientId: googleClientId
            }
          });
          return resp;
        }
      case 'magic-link-login':
        {
          const resp = await auth.authenticate('magic-link-login', request, {
            successRedirect: '/login-link/success',
            // returns User if not defined
            throwOnError: true,
            context: {
              emailAddress: emailAddress,
              redirectUrl: redirectTo
            }
          });
          return resp;
          // return alertServer.redirectWithSuccess(
          //   '/login-link/success',
          //   {
          //     title: 'E-mail sent',
          //     message: 'Please click the link in the E-mail to sign in',
          //   },
          //   { headers },
          // )
        }
      default:
        console.log('default login');
        await auth.authenticate(action, request, {
          successRedirect: `/api/auth/post-sign-in?redirectTo=${redirectTo}`,
          throwOnError: true,
          context: {
            formData
          }
        });
        return alertServer.redirectWithSuccess(`/api/auth/post-sign-in?redirectTo=${redirectTo}`, {
          title: 'Successfully logged in',
          message: ''
        }, {
          headers
        });
    }
  } catch (err) {
    if (err instanceof Response) {
      return err;
    }
    console.log(`Error logging in: ${err.cause} ${err.message}`);
    /*
     * failures come in as __typenames
     * e.g. VerificationTokenExpiredError, VerificationTokenInvalidError, MissingPasswordError
     * PasswordValidationError, PasswordAlreadySetError, NativeAuthStrategyError,
     * InvalidCredentialsError, NotVerifiedError, NativeAuthStrategyError
     */
    switch (err.message) {
      case 'VERIFICATION_TOKEN_INVALID_ERROR':
      case 'VERIFICATION_TOKEN_EXPIRED_ERROR':
      case 'NOT_VERIFIED_ERROR':
        return alertServer.redirectWithError('/re-verify', {
          title: 'Verification error',
          message: 'Token invalid. Please request a new verification e-mail'
        }, {
          headers,
          status: 401
        });
      default:
        return alertServer.redirectWithError('/sign-in', {
          title: 'Sign-in error',
          message: 'Please try again'
        }, {
          headers,
          status: 401
        });
    }
  }
}
export async function loader({
  request
}) {
  let headers;
  let channel;
  const {
    activeAdministrator
  } = await getActiveAdministrator({
    request
  });
  if (activeAdministrator) {
    // const alertServer = getAlertServer()
    // await logout({ request })
    // headers = await alertServer.showError({
    //   title: 'Already signed in',
    //   message: 'Signing you out',
    // })
  }
  // const channel = await activeChannel({ request })
  const {
    settings
  } = await adminSettings({
    request
  });
  const session = await sessionStorage.getSession(request?.headers.get('Cookie'));
  let error = session.get('auth:error');
  if (error) {
    if (isJson(error?.message)) {
      error = JSON.parse(error.message).message;
    } else {
      error = error.message;
    }
  }
  return json({
    error,
    success: false,
    enableEmailLoginLink: settings.globalSettings.enableEmailLoginLink,
    googleClientId: settings.globalSettings.googleClientId
  }, {
    headers: {
      'Set-Cookie': await sessionStorage.commitSession(session)
    }
  });
}
export default function SignInPage() {
  _s();
  const {
    error,
    enableEmailLoginLink,
    googleClientId
  } = useLoaderData();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') || undefined;
  const redirect = searchParams.get('redirectTo');
  const redirectTo = redirect ? '?redirectTo=' + redirect : '';
  const login = useFetcher();
  return <>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl text-gray-900 dark:text-slate-400">
            {token ? 'Step 3: Create password' : 'Log in to Metrix'}
          </h2>
        </div>
        <div className={clsx('sm:mx-auto sm:w-full sm:max-w-md', {
        'mt-8': !error,
        mt: !!error
      })}>
          <div className="py-8 px-4 mx-2 shadow rounded-lg sm:px-10 bg-white dark:bg-slate-700">
            {error ? <ErrorBox title="Sign in error" message={error} className="mb-4" /> : null}
            <login.Form method="POST">
              <fieldset disabled={login.state !== 'idle'} className="space-y-6">
                <input type="hidden" name="redirectTo" value={redirect ?? undefined} />
                {token ? <>
                    <SuccessBox title="Thank you for verifying your email!" message="Please create a password for your account:" />
                    <input type="hidden" name="token" value={token} />
                  </> : <>
                    <FormInput id="email1" name="email" label="Email address" placeholder="name@domain.com" required autoComplete="username" defaultValue="" />
                  </>}
                <PasswordInput name="password" label="Password" required placeholder="*****" autoComplete="current-password" defaultValue="" />
                {token ? <>
                    <FormInput name="confirmPassword" label="Confirm password" required placeholder="*****" autoComplete="password" defaultValue="" />
                  </> : <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input id="rememberMe" name="rememberMe" type="checkbox" className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded disabled:bg-gray-300 disabled:cursor-not-allowed" defaultChecked />
                      <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900 dark:text-slate-400">
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm">
                      <a href="/forgot" className="font-medium text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300">
                        Forgot your password?
                      </a>
                    </div>
                  </div>}

                {login.data && login.state === 'idle' && <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          We ran into a problem signing you in!
                        </h3>
                        <p className="text-sm text-red-700 mt-2">
                          {login.data.message}
                        </p>
                      </div>
                    </div>
                  </div>}

                <Button type="submit" name="strategy" value="form" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <span className="flex gap-4 items-center">
                    {login.state !== 'idle' && <LoadingIndicator className="h-5 w-5" />}
                    {token ? 'Set password' : 'Log in'}
                  </span>
                </Button>
              </fieldset>
            </login.Form>

            {token || !googleClientId ? null : <div className="w-full flex items-center justify-center mt-4 mb-2">
                <login.Form method="POST" className="w-full">
                  <input type="hidden" name="redirectTo" value={redirect ?? undefined} />
                  <Button variant="unstyled" type="submit" name="strategy" value="google" className="w-full p-2 flex items-center justify-center gap-2 outline-none rounded-lg focus:z-10 text-sm font-medium hover:bg-gray-100 dark:hover:bg-slate-800">
                    <img src="/assets/logo-google.svg" className="w-[32px] h-[32px] rounded-2xl" />
                    Login with Google
                  </Button>
                </login.Form>
              </div>}
            {token || !enableEmailLoginLink ? null :
          // <div className="w-full flex items-center justify-center p-4">
          <login.Form method="POST">
                <input type="hidden" name="redirectTo" value={redirect ?? undefined} />
                <fieldset disabled={login.state !== 'idle'} className="mt-4 space-y-4">
                  <legend className="font-medium text-center -mb-2">
                    Receive login link email
                  </legend>
                  <FormInput id="email2" name="email" label="Email address" required placeholder="name@domain.com" autoComplete="email" defaultValue="" />
                  <Button type="submit" name="strategy" value="magic-link-login" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    Login link email
                  </Button>
                </fieldset>
              </login.Form>
          // </div>
          }
          </div>
        </div>
      </div>
    </>;
}
_s(SignInPage, "vaFtDOcN9QIzcpQrmXLthfcNW0Q=", false, function () {
  return [useLoaderData, useSearchParams, useFetcher];
});
_c = SignInPage;
var _c;
$RefreshReg$(_c, "SignInPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;